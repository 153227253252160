
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";

import {
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
    onAuthStateChanged,
  } from "firebase/auth";


const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
};


let app;
let auth;
let db;

if (typeof window !== "undefined") {
   app = initializeApp(firebaseConfig);
  // auth = getAuth(app);
   db = getFirestore(app);
   auth  = initializeAuth(app, {
    persistence: [
      indexedDBLocalPersistence,
      browserLocalPersistence,
      browserSessionPersistence,
    ],
  });
}

export { auth, onAuthStateChanged, db };
import * as React from "react"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { StoreProvider } from "./src/context/store-context"
import AuthProvider from "./src/context/auth-context";
import "./src/styles/reset.css"
import "./src/styles/variables.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
    <AuthProvider>
        <StoreProvider>
          {element}
        </StoreProvider>
    </AuthProvider>
 
)

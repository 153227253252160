exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-policy-jsx": () => import("./../../../src/pages/policy.jsx" /* webpackChunkName: "component---src-pages-policy-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-service-index-jsx": () => import("./../../../src/pages/service/index.jsx" /* webpackChunkName: "component---src-pages-service-index-jsx" */),
  "component---src-pages-shop-cart-jsx": () => import("./../../../src/pages/shop/cart.jsx" /* webpackChunkName: "component---src-pages-shop-cart-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-service-category-page-jsx": () => import("./../../../src/templates/service/category-page.jsx" /* webpackChunkName: "component---src-templates-service-category-page-jsx" */),
  "component---src-templates-service-product-page-jsx": () => import("./../../../src/templates/service/product-page.jsx" /* webpackChunkName: "component---src-templates-service-product-page-jsx" */),
  "component---src-templates-shop-category-page-jsx": () => import("./../../../src/templates/shop/category-page.jsx" /* webpackChunkName: "component---src-templates-shop-category-page-jsx" */),
  "component---src-templates-shop-product-page-jsx": () => import("./../../../src/templates/shop/product-page.jsx" /* webpackChunkName: "component---src-templates-shop-product-page-jsx" */)
}


import { db } from './firebase'
import { doc, setDoc, getDoc  } from "firebase/firestore/lite"; 
import { createUserWithEmailAndPassword } from "firebase/auth";

const addUser = async (auth,name, email, password) => { 
    try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        const user = {
            name: name,
            email: email
          };
        await setDoc(doc(db, "users", result.user.uid), user);
        return("Created user with ID: ", result.user.uid);
      } catch (e) {
        console.error("Error adding user: ", e);
      }
}

const updateFeed = async (feed) => {
  try {
     const resiult = await setDoc(doc(db, "productFeed", "feed"), {feed});
    return("Uppdaterat!");
  } catch (e) {
    console.log(e)
    return("Något gick fel");
  }
}

const getUser = async(uid) =>{

const docRef = doc(db, "users", uid);
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  return docSnap.data();
} else {
  // doc.data() will be undefined in this case
  console.log("No such document!");
}
}

export {addUser, getUser, updateFeed}
import React, { createContext, useContext, useEffect, useState  } from "react";
import { auth } from "../firebase/firebase";
import { onAuthStateChanged } from  "firebase/auth";
import { getUser } from "../firebase/database";

const defaultContext = {
  currentUser: null,
}

export const AuthContext = createContext(defaultContext);
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState(null);
  const [uid, setUid] = useState(null)

  useEffect(() => 
    onAuthStateChanged(auth, (user) => user ? setUid(user.uid) : setUid(null)
  ,[]));

  useEffect(() => {
    if (uid) {
      setCurrentUser({uid, fetching: true})
      getUser(uid).then(data => {
        setCurrentUser({...data,uid, fetching: false})
      })
      .catch(e => console.log(e))
    }else{
      setCurrentUser(null)
    }
  }, [uid])

  return (
    <AuthContext.Provider value={{currentUser, setCurrentUser}}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthValue(){
  return useContext(AuthContext)
}

export default AuthProvider;